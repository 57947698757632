.wrapperInovation{
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    border-left: solid 1px #ffffff80;
    box-sizing: border-box;
    padding: 0 10px 0px 10px;
}
.wrapperInovation>span{
    position: absolute;
    border-bottom: solid 1px #ffffff80;
    width: 50%;
    max-width: 700px;
    right: -6%;
    bottom: 0;
}
.wrapperTop{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}
.wrapperTop h1{
    width: 30%;
}
.wrapperTop h2{
    width: 70%;
}
.wrapperCards{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    margin-bottom: 20px;
    position: relative;
}
.commonCard{
    width: 30%;
    padding-top: 30%;
    position: relative;
}
.commonCard>div{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.commonCard>div img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.commonCard>div p{
    margin-top: 20%;
    position: absolute;
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.commonCard>div p span{
    position: absolute;
    border-bottom: solid 1px #ffffff80;
    width: 10%;
    max-width: 50px;
    left: -12%;
    top: 8px;
}
@media only screen and (max-width: 767px) {

    .wrapperTop{
        padding: 0;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: flex-start;
    }
    .wrapperTop h1{
        width: 100%;
        margin-bottom: 15px;
    }
    .wrapperTop h2{
        width: 100%;
    }
    .wrapperCards{
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .commonCard{
        width: 100%;
        padding-top: 100%;
        margin-bottom: 15px;
    }
    .commonCard:last-child{
        margin-bottom: unset;
    }
}