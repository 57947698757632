.wrapperDesign{
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    border-left: solid 1px #ffffff80;
    box-sizing: border-box;
    padding: 0 10px 40px 10px;
}
.wrapperTop{
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    margin-bottom: 40px;
}
.wrapperTop span{
    position: absolute;
    border-bottom: solid 1px #ffffff80;
    width: 100%;
    max-width: 450px;
    bottom: -20px;
    left: calc(-2.5% - 20px);
}
.wrapperTop img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-width: 250px;
}
.wrapperSlider{
    width: 100%;
    padding-top: 56.6%;
    position: relative;
}
.wrapperSlider>div:not(.shadow){
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.wrapperSlider div:not(.shadow){
    height: 100%;
}
.slide{
    position: relative;
}
.slide>img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.shadow{
    position: absolute;
    top: 0;
    left: 0;
    width: 30%;
    height: 100%;
    padding: 4%;
    box-sizing: border-box;
    background-color: #09172682;
}
.shadow h1{
    font-size: calc(4.5rem + ((1vw - 7.67px) * 0.5204));
    margin-bottom: 15px;
}
/*arrows*/
.icon_arrow_point_to{
    background-image: url(../img/arrow.svg) !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    transition: all .3s linear;
    backface-visibility: hidden;
    width: 35px !important;
    height: 35px !important;
    z-index: 3;
}
.icon_arrow_point_to::before{
    content: '' !important; 
}
.icon_arrow_next{
    left: calc(20% + 20px) !important;
    top: calc(100% - 40px) !important;
    transform: translate(0, -50%) scale(1) rotate(0deg) !important;
    z-index: 1;
}
.icon_arrow_prev{
    left: calc(0% + 20px) !important;
    top: calc(100% - 40px) !important;
    transform: translate(0, -50%) scale(1) rotate(180deg) !important;
    z-index: 1;
}
.icon_arrow_next:active{
    transform: translate(0, -50%) scale(1.5) rotate(0) !important;
}
.icon_arrow_prev:active{
    transform: translate(0, -50%) scale(1.5) rotate(180deg) !important;
}
@media only screen and (max-width: 767px) {
    .wrapperSlider>div:not(.shadow){
        position: relative;
    }
    .wrapperTop img{
        width: 50%;
    }
    .wrapperTop span{
        bottom: 0;
    }
    .wrapperSlider{
        padding-top: unset;
    }
    .shadow{
        position: relative;
        width: 100%;
        margin-top: -5px;
        height: 250px;
        display: block;
    }
    .shadow h1{
        text-align: center;
        font-size: 28px;
        margin-bottom: 10px;
    }
    .shadow p{
        padding: 0 10%;
        text-align: center;
    }
    .icon_arrow_next{
        left: calc(90%) !important;
        top: calc(100% + 125px) !important;
    }
    .icon_arrow_prev{
        left: calc(-2%) !important;
        top: calc(100% + 125px) !important;
    }
    .slide{
        width: 100%;
        display: inline-block;
        padding-top: 126.25%;
    }
    .slide img{
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
    }
}