.wrapperVista360{
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px 10px 20px 10px;
    box-sizing: border-box;
    border-left: solid 1px #ffffff80;
}
.wrapperTop{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
.wrapperMatter{
    width: 100%;
    padding-top: 56.25%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.wrapperMatter>div{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.wrapperMatter>div>iframe{
    width: 100%;
    height: 100%;
    border: none;
    object-fit: contain;
}