.wrapperHeader{
    background-color: #000;
    width: 100%;
    height: 35px;
    padding: 5px 5%;
    box-sizing: border-box;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-content: center;
    z-index:2;
}
.wrapperLogo{
    width: 100%;
    max-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.wrapperLogo>img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.wrapperContact{
    width: 100%;
    max-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.wrapperContact>img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.wrapperMenus{
    width: 80%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.wrapperDisenio{
    width: 100%;
    max-width: 65px;
    margin: 0 0 0 50px;
    margin-bottom: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.wrapperAmenidades{
    width: 100%;
    max-width: 110px;
    margin: 0 0 0 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.wrapperUbicacion{
    width: 100%;
    max-width: 110px;
    margin: 0 0 0 50px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.wrapperDisenio>img,.wrapperAmenidades>img,.wrapperUbicacion>img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
@media only screen and (max-width: 767px) {
    .wrapperMenus{
        display: none;
    }
}