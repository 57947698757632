@font-face{
  font-family: 'Avenir-Light';
  src: url('fonts/Avenir-Light.ttf') format('truetype');
  font-display: swap;
}
@font-face{
  font-family: 'Avenir-Medium';
  src: url('fonts/Avenir-Medium.ttf') format('truetype');
  font-display: swap;
}
@font-face{
  font-family: 'Aeonik-Regular';
  src: url('fonts/Aeonik-Regular.otf') format('opentype');
  font-display: swap;
}
@font-face{
  font-family: 'Aeonik-Thin';
  src: url('fonts/Aeonik-Thin.otf') format('opentype');
  font-display: swap;
}
@font-face{
  font-family: 'Aeonik-Light';
  src: url('fonts/Aeonik-Light.otf') format('opentype');
  font-display: swap;
}
@font-face{
  font-family: 'Aeonik-LightItalic';
  src: url('fonts/Aeonik-LightItalic.otf') format('opentype');
  font-display: swap;
}
*{
	margin: 0;
	padding: 0;
	text-decoration: none;
  color: #fff;
  font-size: calc(0.775rem + ((1vw - 7.67px) * 0.5204));
}
html{
  scroll-behavior: smooth;
}
body{
  background-color: #000;
}
p{
  font-family: 'Aeonik-Light';
  font-weight: normal;
}
h1{
  font-family: 'Aeonik-Thin';
  font-size: calc(1.5rem + ((1vw - 7.67px) * 0.5204));
  font-weight: normal;
}
h2{
  font-family: 'Aeonik-Thin';
  font-size: calc(0.9rem + ((1vw - 7.67px) * 0.5204));
  font-weight: normal;
}

@media (min-width: 1920px) {
  *{
    font-size: 20px;
  }
}
@media (max-width:1024px){
  *{
    font-size: 13px;
  }
}
@media (max-width: 767px) {
  h1{
    font-size: 20px;
  }
  h2{
    font-size: 17px;
  }
}