.wrapperAmenidades{
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    padding: 20px 10px 20px 10px;
    box-sizing: border-box;
    border-left: solid 1px #ffffff80;
}
.wrapperTop{
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    margin-bottom: 20px;
}
.wrapperTop img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-width: 390px;
}
.wrapperImg{
    width: 60%;
    padding-top: 35%;
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-content: center;
}
.wrapperImg>div{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.wrapperImg>div>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.wrapperShadow{
    width: 40%;
    padding-top: 35%;
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-content: center;
}
.wrapperShadow>div{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #5b646b99;
    padding: 5%;
    box-sizing: border-box;
}
.wrapperShadow h1{
    width: 100%;
    text-align: right;
}
.wrapperShadow p{
    width: 100%;
    margin: 5% 0;
}
.wrapperShadow ol{
    padding-left: 5%;
    font-family: 'Aeonik-Light';
}
@media (max-width: 1024px) {
    .wrapperImg{
        padding-top: 50%;
    }
    .wrapperShadow{
        padding-top: 50%;
    }
    .wrapperShadow p{
      font-size: calc(1.125rem + ((1vw - 10.24px) * 1.9455));
    }
}
@media (max-width: 767px) {
   .wrapperAmenidades{
       flex-wrap: wrap;
       align-content: flex-start;
   }
   .wrapperImg{
       width: 100%;
       padding-top: 0;
    }
   .wrapperImg>div{
       position: relative;
   }
   .wrapperShadow{
       width: 100%;
       padding-top: 0;
       margin-top: -5px;
    }
   .wrapperShadow>div{
       position: relative;
   }
   .wrapperShadow p{
    font-size: 13px;
  }
   .wrapperTop span{
        position: absolute;
        border-bottom: solid 1px #ffffff80;
        width: 100%;
        max-width: 100px;
        top: 0;
        left: calc(100% - 100px);
    }
    .wrapperTop span:last-child{
        bottom: 0;
        max-width: 200px;
        left: calc(-5% - 5px);
    }
  }