.wrapperUbicacion{
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    padding: 20px 10px 20px 10px;
    box-sizing: border-box;
    
}
.wrapperUbicacion:after {
    padding: 0;
    margin: 0;
    display: block;
    content: "";
    width: 1.1px;
    height: 70%;
    background-color: #ffffff80;
    position: absolute;
    left: 0;
    top: -0;
  }
.wrapperTop{
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    margin-bottom: 20px;
}
.wrapperTop img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-width: 390px;
}
.wrapperMap{
    width: 65%;
    padding-top: 40%;
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-content: center;
}
.wrapperMap>div{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.wrapperShadow{
    width: 35%;
    padding-top: 40%;
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-content: center;
}
.wrapperShadow>div{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #5b646b99;
    padding: 5%;
    box-sizing: border-box;
}
.wrapperShadow h1{
    width: 100%;
}
.wrapperShadow h2{
    width: 100%;
    margin: 20px 0;
}
.wrapperShadow p{
    width: 100%;
    margin: 15px 0;
}
.wrapperShadow p.first{
  font-family: 'Aeonik-Regular';

}
.imgBlur{
    width: 100%;
    padding-top: 40%;
    position: relative;
    margin: 30px 0 10px 0;
}
.imgBlur>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}
.imgUbicacion{
    width: 100%;
    padding-top: 40%;
    position: relative;
    margin: 10px 0 20px 0;
}
.imgUbicacion>img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
}
@media (max-width: 1024px) {
    .wrapperMap{
        padding-top: 50%;
    }
    .wrapperShadow{
        padding-top: 50%;
    }
    .wrapperShadow p{
      font-size: calc(1.125rem + ((1vw - 10.24px) * 1.9455));
    }
}
@media (max-width: 767px) {
    .wrapperUbicacion{
       flex-wrap: wrap;
       align-content: flex-start;
    }
    .wrapperMap{
        width: 100%;
        padding-top: 130%;
    }
    .wrapperShadow p{
        font-size: 13px;
      }
    .wrapperShadow{
       width: 100%;
       padding-top: 0;
    }
    .wrapperShadow>div{
       position: relative;
    }
    .wrapperUbicacion:after {
       display: none;
    }
    .imgBlur{
        width: 140%;
        padding-top: 100%;
        margin-left: calc(-5% - 15px);
        margin-right: calc(-5% - 15px);
    }
    .imgUbicacion{
        width: 140%;
        padding-top: 100%;
        margin-left: calc(-5% - 15px);
        margin-right: calc(-5% - 15px);
    }
    .imgUbicacion>img{
        object-fit: cover;
        right: 0;
        width: auto;
        height: 100%;
        left: unset;
    }
  }