.wrapperTop{
    width: 100%;
    position: relative;
    padding-top:35px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.background{
    width: 100%;
    position: relative;
}
.wrapperText{
    position: absolute;
    width: 100%;
    height: calc(100% - 45px);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.titleTop{
    font-family: 'Avenir-Light';
    letter-spacing: 15px;
    border-bottom: solid 1px #ffffff80;
    padding-bottom: 5px;
    margin-bottom: 10px;
    min-height: 35px;
}
.textTop{
    font-family: 'Avenir-Light';
    width: 50%;
    line-height: 1;
}
.wrapperRight{
    width: 70%;
    padding: 30px 0 30px 30px;
    box-sizing: border-box;
}
.wrapperLeft{
    width: 30%;
    padding: 30px 30px 20% 0;
    margin-top: 25px;
    box-sizing: border-box;
    border-left: solid 1px #ffffff80;
}
.TextCommon{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
    position: relative;
}
.btnCommon{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0% 10%;
    cursor: pointer;
    box-sizing: border-box;
    width: 100%;
}
.btnCommon p{
    font-family: 'Avenir-Light';

}
.btnCommon>img{
    width: 14px;
    height: 14px;
    margin-left: 10px;
}
.text{
    padding: 0 10%;
    height: 80%;
    box-sizing: border-box;

}
.text p{
    max-width: 350px;
    font-family: 'Avenir-Light';

}
.text ul{
    list-style: none;
    margin: 10px 0;
}
.text ul li{
    font-family: 'Avenir-Light';
}
.title{
    padding: 5px 10%;
    height: 10%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

}
.title img{
    height: 28px;
    width: 100%;
    max-width: 200px;
}
.disenio .title{
    margin-top: 13px;
}
.disenio .title img{
    max-width: 130px;
}
.disenio .title span{
    position: absolute;
    border-bottom: solid 1px #ffffff80;
    width: 10%;
    max-width: 50px;
    top: 2px;
    left: 0;
}
.disenio .title span:last-child{
   bottom: 0;
}
.disenio .text{
    margin: 10px 0;
}
.disenio .text p{
    margin-bottom: 15px;
}
.amenidades{
    margin-top: 40px;
}
.amenidades .title span{
    position: absolute;
    border-bottom: solid 1px #ffffff80;
    width: 150%;
    top: 2px;
    left: -140%;
}
.amenidades .title img{
    margin: 20px 0px;
}
.ubicacion{
    margin-top: 40px;
}
.ubicacion .title span{
    position: absolute;
    border-bottom: solid 1px #ffffff80;
    width: 100%;
    top: 2px;
    left: -60%;
}
.ubicacion .title img{
    margin: 20px 0px 10px 0px;
}
.ubicacion .text p{
    margin-bottom: 15px;
}
@media only screen and (max-width: 767px) {

    .wrapperTop{
        height: auto;
        margin-bottom: 20px;
    }
    .background{
        position: absolute;
    }

    .wrapperText{
        position: relative;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-content: flex-start;
    }
    .wrapperText p{
        line-height: 1;
    }
    .wrapperRight{
        width: 95%;
        padding: 5%;
        border-left: solid 1px #ffffff80;
    }
    .wrapperLeft{
        width: 95%;
        padding: 5% 5% 5% 0%;
        margin: 0;
    }
    .title{
        padding: 2% 5%;
    }
    .text{
        padding: 2% 5%;
    }
    .btnCommon{
        padding: 0 5%;
    }
    .titleTop{
        border-bottom: none;
        letter-spacing: 10px;
    }
    .textTop{
        width: 100%;
    }

    .disenio .title span{
        position: absolute;
        border-bottom: solid 1px #ffffff80;
        width: 100%;
        max-width: 95%;
        bottom: 0;
        left: -5%;
    }
    .disenio .title span:last-child{
       display: none;
    }
    .amenidades .title img{
        margin: 10px 0px; 
    }
    .amenidades .title span{
        width: 90%;
        left: -5%;
    }
    .disenio .text p,.amenidades .text p,.ubicacion .text p{
        width: 100%;
        max-width: 90%;
    }
}

