.wrapperAvanceObra{
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px 10px 20px 10px;
    box-sizing: border-box;
    border-left: solid 1px #ffffff80;
}

.wrapperAvanceObra>img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}