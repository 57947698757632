.wrapperSplash{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-content: center;
    position: fixed;
}
.background{
    background-image: url('./../img/Background.jpg');
    background-size: cover;
    background-position:center;
    background-repeat: no-repeat;
}

.wrapperLogo{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.wrapperLogo>img{
    width: 100%;
    max-width: 150px;
}
.wrapperBtn{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center; 
    margin: 40px 0 5px 0;
    cursor: pointer;
}
.wrapperBtn>img{
    padding: 10px;
    box-sizing: border-box;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    border: solid 1px #fff;
}
.labelComenzar{
    margin: 15px 100%;
    letter-spacing: 6px;
}