.wrapperSections{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    position: relative;
    margin-bottom: 20px;
    overflow: hidden;
}
.wrapperBody{
    padding: 0 5% 0 0;
    margin: 5% 0 0 5%;
    width: 95%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
}
@media only screen and (max-width: 767px) {
    .wrapperBody{
        margin-top: 10%;
    }

}