.wrapperContacto{
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    padding: 20px 10px 20px 10px;
    box-sizing: border-box;
}
.wrapperTop{
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    margin-bottom: 20px;
}
.wrapperTop img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-width: 390px;
}
.wrapperBody{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #26272782;
}
.dataContacto{
    width: 25%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    padding: 15px;
    box-sizing: border-box;
}
.dataContacto>p{
    margin-top: 15px;
}
.tel{
    margin: 15px 0 5px 0;
}
.tel label{
    font-family: 'Aeonik-Light';
}
.tel>p{
    font-size: calc(1.2rem + ((1vw - 7.67px) * 0.5204));
}
.formContacto{
    width: 75%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    padding: 15px 15px 30px 15px;
    box-sizing: border-box;
    position: relative;
}
.formContacto::after{
    padding: 0;
    margin: 0;
    display: block;
    content: "";
    width: 1px;
    height: 97%;
    background-color: #ffffff80;
    position: absolute;
    left: 0;
    top: 1.5%;
}
.wrapperAsesor{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 15px;
    box-sizing: border-box;
}
.imgAsesor{
    width: 25%;
    padding-top: 30%;
    position: relative;
}
.imgAsesor>div{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;
}
.imgAsesor>div img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-width: 150px;
    max-height: 150px;
    box-shadow: 0px 1px 20px 0px #4d4d4d;
    border-radius: 100%;
}
.dataAsesor{
    width: 50%;
    padding-top: 30%;
    position: relative;
}
.dataAsesor>div{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    padding: 10px;
    box-sizing: border-box;
}
.TextAsesor{
    width:100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
}
.name{
    font-family: 'Aeonik-Regular'; 
    font-size: calc(1.2rem + ((1vw - 7.67px) * 0.5204));
}
.frase{
    font-family: 'Aeonik-LightItalic';
    margin-top: 15px;
    padding-right: 10px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 80%;
}
.frase::-webkit-scrollbar-track{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.frase::-webkit-scrollbar{
	width: 8px;
    background-color: #F5F5F5;
    border-radius: 25px;
}

.frase::-webkit-scrollbar-thumb{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #303030;
}
.numeroAsesor{
    width: 25%;
    padding-top: 30%;
    position: relative;
}
.numeroAsesor>div{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    padding: 10px;
    box-sizing: border-box;
}
.contactAsesor{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    padding-left: 10%;
}
.contactAsesor a{
    margin-top: 10px;
    width: 100%;
}
.contactAsesor a>p{
    margin-top: 5px;
    width: 100%;
}
p.telAsesor{
    font-family: 'Aeonik-Regular'; 
    font-size: calc(1.2rem + ((1vw - 7.67px) * 0.5204));
    margin-top: unset;
}
.wrapperForm{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
}
.form{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    margin-top: 15px;
}
.duda{
    background-color: #25262699;
    width: 90%;
    min-height: 60px;
    outline: none;
    border: none;
    margin: 10px 0;
}
.email{
    background-color: #25262699;
    width: 45%;
    min-height: 25px;
    outline: none;
    border: none;
    margin: 10px 50% 10px 0;
}
.btnSubmit{
    width: 100%;
    max-width: 70px;
    border: solid 1px #ffffff80;
    background-color: transparent;
    padding: 5px;
    box-sizing: border-box;
    cursor: pointer;
}
.gracias{
    margin: 20px;
    width: 100%;
}
.gracias p{
    margin-top: 5px;
}
@media (max-width: 767px) {
    .wrapperContacto{
       flex-wrap: wrap;
       align-content: flex-start;
    }
    .wrapperTop{
        justify-content: center;
        align-items: center;
    }
   .wrapperTop img{
        max-width: 260px;
    }
    .wrapperBody{
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: flex-start;
    }
    .dataContacto{
        width: 100%;
        justify-content: center;
    }
    .dataContacto h2{
        text-align: center;
    }
    .formContacto{
        width: 100%;
        padding: 15px 10px 30px 10px;
    }
    .formContacto::after{
        padding: 0;
        margin: 0;
        display: block;
        content: "";
        width: 97%;
        height: 1px;
        left: 1.5%;
        top: 0;
    }
    .formContacto h2{
        text-align: center;
    }
    .wrapperAsesor{
        flex-wrap: wrap;
        padding: 15px 0;
    }
    .imgAsesor{
        width: 100%;
        padding-top: 70%;
        display: flex;
        justify-content: center;
    }
    .imgAsesor>div{
        max-width: 150px;
        max-height: 150px;
        top: 0;
        left: unset;
    }
    .dataAsesor{
        width: 100%;
        padding-top: unset;
    }
    .dataAsesor>div{
        position: relative;
        padding: 5px;
    }
    .numeroAsesor{
        width: 100%;
        padding-top: unset;
    }
    .numeroAsesor>div{
        position: relative;
    }
    .numeroAsesor>p{
        position: relative;
    }
    .contactAsesor{
        padding-left: unset;
    }
    p.telAsesor{
        font-size: calc(1.8rem + ((1vw - 7.67px) * 0.5204));
        letter-spacing: 3px;
    }
    .duda{
        width: 100%;
    }
    .email{
        width: 100%;
        margin: 10px 0;
    }
    .tel p{
        font-size: calc(1.8rem + ((1vw - 7.67px) * 0.5204));
        letter-spacing: 3px;
        width: 100%;
        text-align: center;
    }
    .tel{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: center;
    }
    .tel label{
        width: 100%;
        text-align: center;
    }
    .frase{
        height: auto;
    }
  }